<template>
    <div class="app-container menus">
        <el-form :model="queryParams" v-hasPermi="['monitor:operlog:list']" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" style="width: 100%;height: 0.5%;text-align: left;">
            <el-form-item label="系统模块" prop="title">
                <el-input
                        v-model="queryParams.title"
                        placeholder="请输入系统模块"
                        clearable
                        size="small"
                        style="width: 240px;"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="操作人员" prop="operName">
                <el-input
                        v-model="queryParams.operName"
                        placeholder="请输入操作人员"
                        clearable
                        size="small"
                        style="width: 240px;"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="类型" prop="businessType">
                <el-select
                        v-model="queryParams.businessType"
                        placeholder="操作类型"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option
                            v-for="dict in businessType"
                            :key="dict.id"
                            :label="dict.value"
                            :value="dict.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                        v-model="queryParams.status"
                        placeholder="操作状态"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option
                            v-for="dict in sysCommonStatus"
                            :key="dict.id"
                            :label="dict.value"
                            :value="dict.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="操作时间">
                <el-date-picker
                        v-model="dateRange"
                        size="small"
                        style="width: 240px"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-hasPermi="['monitor:operlog:list']">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery" v-hasPermi="['monitor:operlog:list']">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        :disabled="multiple"
                        @click="handleDelete"
                        v-hasPermi="['monitor:operlog:remove']"
                >删除</el-button>
            </el-col>
<!--            v-has1Permi="['monitor:operlog:remove']"-->
            <el-col :span="1.5">
                <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        @click="handleClean"
                        v-hasPermi="['monitor:operlog:remove']"
                >清空</el-button>
            </el-col>
        </el-row>

        <el-table ref="tables"  :data="list" @selection-change="handleSelectionChange" >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="日志编号" align="center" prop="operId" />
            <el-table-column label="系统模块" align="center" prop="title" width="150px" />
            <el-table-column label="操作类型" align="center" prop="businessType">
                <template slot-scope="scope">
                    <el-tag type="primary"  plain v-if="scope.row.businessType === 0" >其他</el-tag>
                    <el-tag type="success"  plain v-if="scope.row.businessType === 1" >新增</el-tag>
                    <el-tag type="info"  plain v-if="scope.row.businessType === 2" >修改</el-tag>
                    <el-tag type="danger"  plain v-if="scope.row.businessType === 3" >删除</el-tag>
                    <el-tag type="warning"  plain v-if="scope.row.businessType === 5" >删除</el-tag>
                    <el-tag type="danger"  plain v-if="scope.row.businessType === 9" >清空</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="请求方式" align="center" prop="requestMethod" />
            <el-table-column label="操作人员" align="center" prop="operName" width="100" :show-overflow-tooltip="true" />
            <el-table-column label="操作地址" align="center" prop="operIp" width="130" :show-overflow-tooltip="true" />
            <el-table-column label="操作地点" align="center" prop="operLocation" :show-overflow-tooltip="true" />
            <el-table-column label="操作状态" align="center" prop="status">
                <template slot-scope="scope">
                    <el-tag type="primary" plain v-if="scope.row.status === '0'" >成功</el-tag>
                    <el-tag type="danger"  plain v-else >失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作日期" align="center" prop="operTime" width="180">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.operTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-view"
                            @click="handleView(scope.row,scope.index)"
                    >详细</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
        />

        <el-dialog title="操作日志详细" :visible.sync="open" width="700px" append-to-body >
            <el-form ref="form" :model="form" label-width="100px" size="mini" style="width: 100%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="操作模块：">{{ form.title }} / {{ typeFormat(form) }}</el-form-item>
                        <el-form-item label="登录信息：">
                            {{ form.operName }} / {{ form.operIp }} / {{ form.operLocation }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="请求地址：">{{ form.operUrl }}</el-form-item>
                        <el-form-item label="请求方式：">{{ form.requestMethod }}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="操作方法：">{{ form.method }}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="请求参数：">{{ form.operParam }}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="返回参数：">{{ form.jsonResult }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="操作状态：">
                            <div v-if="form.status === '0'">正常</div>
                            <div v-else-if="form.status === '1'">失败</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="操作时间：">{{ parseTime(form.operTime) }}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="异常信息：" v-if="form.status === 1">{{ form.errorMsg }}</el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="open = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {list,delOperlog,cleanOperlog} from "@/api/monitor/operLog"
    import store from '@/store'
    export default {
        name: "Operlog",
        data() {
            return {
                // 总条数
                total: 0,
                // 选中数组
                ids: [],
                // 非多个禁用
                multiple: true,
                // 表格数据
                list: [],
                // 是否显示弹出层
                open: false,
                businessType:[{id:0,value: '其它'},{id: 1,value: '新增'},{id: 2,value: '修改'},{id: 3,value: '删除'},{id: 9,value: '清空'}],
                sysCommonStatus:[{id:0,value: '成功'},{id: 1,value: '失败'}],
                // 显示搜索条件
                showSearch: true,
                // 日期范围
                dateRange: [],
                // 表单参数
                form: {},
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    title: undefined,
                    operName: undefined,
                    businessType: undefined,
                    status: undefined,
                    beginTime:undefined,
                    endTime:undefined
                }
                // 操作类型

            }
        },
        created() {
            this.getList();
        },
        activated(){
            this.getList();
        },
        methods: {
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.dateRange = [];
                this.queryParams.beginTime = undefined;
                this.queryParams.endTime = undefined;
                this.resetForm("queryForm");
                // this.$refs.tables.sort(this.defaultSort.prop, this.defaultSort.order)
                this.handleQuery();
            },
            /** 查询登录日志 */
            getList() {
                const dataTime = this.dateRange;
                if (dataTime.length > 0){
                    this.queryParams.beginTime = dataTime[0];
                    this.queryParams.endTime = dataTime[1];
                }
                list(this.queryParams).then( res => {
                        this.list = res.data.data;
                        this.total = res.data.pageBean.count;
                    }
                );
            },
            // 操作日志类型字典翻译
            typeFormat(row, column) {
                // 业务类型（0其它 1新增 2修改 3删除）
                const type = row.businessType;
                const map = {0: '其他',1: '新增',2: '修改',3: '删除',9: '清空'};
                return map[type];
            },
            /** 详细按钮操作 */
            handleView(row) {
                this.open = true;
                this.form = row;
            },
            handleSelectionChange(val) {
                this.ids = val.map(item => item.operId);
                this.multiple = !val.length;
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const operIds = row.operId || this.ids;
                this.$confirm('是否确认删除日志编号为"' + operIds + '"的数据项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delOperlog({operIds}).then(res => {
                        if (res.status === 200 ){
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            handleClean(){
                this.$confirm('是否确认清空所有操作日志数据项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    cleanOperlog().then(res => {
                        if (res.status === 200 ){
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '清除成功!'
                            });
                        }
                    })
                }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
    /*main-container全局样式*/
    .app-container {
        padding: 20px;
    }
    .mb8 {
        margin: 0 -5px 8px;
    }


</style>