import request from '@/utils/request'
import qs from 'qs'

// 查询操作日志列表
export function list(query) {
    return request({
        url: 'operLog/selectOperLogList',
        method: 'get',
        params: query
    })
}

// 删除操作日志
export function delOperlog(operIds) {
    return request({
        url: 'operLog/deleteOperLogByIds',
        method: 'delete',
        data: qs.stringify(operIds, { arrayFormat: 'comma' })
    })
}

// 清空操作日志
export function cleanOperlog() {
    return request({
        url: 'operLog/cleanOperLog',
        method: 'delete'
    })
}